<template>
  <div class="w100s">
    <div style="display: flex;justify-content: flex-start;align-items: center;background-color: #1352ff;padding: 1rem;">
      <div @click="tothe">
        <svg style="width: 2rem;height: 2rem" t="1658915720127" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4176" width="200" height="200"><path d="M452.266667 507.733333l-29.866667 29.866667 29.866667 29.866667 115.2 115.2 29.866666-29.866667-115.2-115.2L597.333333 422.4l-29.866666-29.866667-115.2 115.2z m81.066666 388.266667c200.533333 0 362.666667-162.133333 362.666667-362.666667S733.866667 170.666667 533.333333 170.666667 170.666667 332.8 170.666667 533.333333 332.8 896 533.333333 896z m0-42.666667C358.4 853.333333 213.333333 708.266667 213.333333 533.333333S358.4 213.333333 533.333333 213.333333 853.333333 358.4 853.333333 533.333333 708.266667 853.333333 533.333333 853.333333z" fill="#ffffff" p-id="4177"></path></svg> </div>
      <div style="color: #fff;font-size: 1rem">{{$t('lang.131')}}</div>
      <div></div>
    </div>

    <div style="background-color: #1352ff;padding: 0 2rem;padding-bottom: 1rem;border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;">
      <div style="padding-top: 0.9rem;" v-for="item in getcashflow" :key="item.id">
        <div style="background-color: #2f33b2;color: #fff;padding: 1rem;
                border-radius: 1rem;
                display: flex;align-items: center;
                justify-content: space-evenly;
                font-size: 0.9rem;">
          <div >
            <svg style="width: 1.5rem;height: 1.5rem" t="1658916138826" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10701" width="200" height="200"><path d="M325.404444 282.168889s79.644444 65.991111 182.044445 65.991111 184.32-65.991111 184.32-65.991111 88.746667-79.644444 43.235555-113.777778-113.777778 11.377778-113.777777 11.377778-52.337778-56.888889-113.777778-56.888889-111.502222 56.888889-111.502222 56.888889-68.266667-45.511111-113.777778-11.377778 43.235556 113.777778 43.235555 113.777778z m559.786667 439.182222s29.582222-86.471111-38.684444-227.555555c-72.817778-147.911111-170.666667-150.186667-170.666667-150.186667s-72.817778 45.511111-168.391111 45.511111-166.115556-45.511111-166.115556-45.511111-100.124444 0-172.942222 152.462222c-65.991111 136.533333-38.684444 227.555556-38.684444 227.555556s-154.737778 195.697778 377.742222 195.697777c532.48 0 377.742222-197.973333 377.742222-197.973333z m-380.017778 116.053333c-106.951111 0-193.422222-86.471111-193.422222-191.146666 0-104.675556 86.471111-191.146667 193.422222-191.146667s193.422222 86.471111 193.422223 191.146667c0 104.675556-86.471111 191.146667-193.422223 191.146666zM384.568889 637.155556l125.155555 125.155555 125.155556-125.155555-125.155556-125.155556-125.155555 125.155556z m0 0" fill="#ffffff" p-id="10702"></path></svg>
          </div>
          <div>
            <div v-if="item.state == 0">{{$t('lang.132')}}</div>
            <div v-if="item.state == 1">{{$t('lang.133')}}</div>
            <div v-if="item.state == 2">{{$t('lang.134')}}</div>
            <div>{{ item.creat_time }}</div>
          </div>
          <div>
            <div>{{ item.money }}</div>
          </div>
        </div>
      </div>
    </div>


  </div>

</template>

<script>
import {
  getAccounts,
  USDTbalanceOf
} from "@/utils/Mapping";
import axios from "@/utils/axios";
import Web3 from "web3";
import bl1 from "@/assets/image/lb1.png";
import bl2 from "@/assets/image/lb2.png";
import bl3 from "@/assets/image/lb3.png";
// import axios from "@/utils/axios";
import { Toast } from 'vant';
import { formatDate } from '@/utils';
export default {
  name: "index",
  inject: ['reload'],
  data() {
    return {
      getcashflow:[],
      addressxx:{},
      defaultAddress:'',
      usdtmoney:'',
      current: 0,
      value1:1,
      dh:0,
      images: [
        bl1,
        bl2,
        bl3,
      ],
      activeNames: ['0'],
      activeName: ['0'],
      activeNamee: ['0'],
      showPopover: false,
      actions: [
        { text: 'Engilsh',key:'gb' },
        { text: 'Chinese',key:'cn'  },
        { text: 'Japan',key:'jp'  },
        { text: 'Spain',key:'es'  },
        { text: 'Vietnam',key:'vn'  },
      ],
    };
  },
  mounted() {
  },
  watch: {

  },
  components: {
  },

  //初始化
  async created(){
    //网络添加切换
    // await this.getChainId()
    await this.initWeb3Account()
  },

  methods: {
    onChange(index) {
      this.current = index;
    },
    async chushi(){

    },
    async tothe(){
      this.$router.push({ path: "/personal" });
    },
    async maxusdt(){
      this.value1 = this.addressxx.money
      this.dh1 = this.addressxx.money*4.5
    },
    async initWeb3Account() {
      console.log("initWeb3Account 方法");
      // 检查新版MetaMask
      if (window.ethereum) {
        this.provider = window.ethereum;
        try {
          // 请求用户账号授权
          await window.ethereum.enable();
        } catch (error) {
          // 用户拒绝了访问
          console.log("User denied account access");
        }
      }
      // 老版 MetaMask
      else if (window.web3) {
        this.provider = window.web3.currentProvider;
      }
      // 如果没有注入的web3实例，回退到使用 Ganache
      else {
        this.provider = new Web3.providers.HttpProvider("http://127.0.0.1:7545");
      }
      getAccounts().then(accs => {
        this.defaultAddress = accs
        console.log(accs)
        USDTbalanceOf(this.defaultAddress).then(res =>{
          console.log("USDTbalanceOf",res)
          this.usdtmoney = res/(10**this.$store.state.daou.decimal);
          //地址信息
          let that = this
          axios.post('/index/getaddressxx',{address: this.defaultAddress,chainId:this.$store.state.daou.chainId,})
              .then(function (response) {
                console.log('getaddressxx',response.data);
                that.addressxx = response.data.data
              })
          axios.post('/index/getwithdrawalsrecord',{address: this.defaultAddress,chainId:this.$store.state.daou.chainId,})
              .then(function (response) {
                console.log('getaddressxx',response.data);
                that.getcashflow = response.data.data
              })
        })
      })


    },

    onSelect(action) {
      console.log(action)
      this.$i18n.locale = action.key
      localStorage.setItem('locale',action.key)
      this.reload();
    },
    async canyu(){
      Toast("需要大于10USDT");
      console.log('canyu');
    },
    async getChainId(){
      let id = window.ethereum.chainId;
      console.log(id)
      if(id != '0x38'){
        window.ethereum.request({
          method: 'wallet_addEthereumChain', // Metamask的api名称
          params: [{
            chainId: "0x38", // 网络id，16进制的字符串
            chainName: "BNB Smart Chain Mainnet", // 添加到钱包后显示的网络名称
            rpcUrls: [
              'https://bsc-dataseed1.ninicoin.io/', // rpc地址
            ],
            iconUrls: [
              'https://testnet.hecoinfo.com/favicon.png' // 网络的图标，暂时没看到在哪里会显示
            ],
            blockExplorerUrls: [
              'https://bscscan.com/' // 网络对应的区块浏览器
            ],
            nativeCurrency: {  // 网络主币的信息
              name: 'bnb',
              symbol: 'bnb',
              decimals: 18
            }
          }]
        });
        // window.location.reload();
        console.log('no')
      }else {
        console.log('yes')
      }
      //！链id不是马上拿到的，如果通过链id来判断是不是主网的方式，请注意异步
    },

  },
  filters: {
    ellipsis(value) {
      let len = value.length;
      if (!value) return "";
      if (value.length > 8) {
        return value.substring(0, 4) + "..." + value.substring(len - 4, len);
      }
      return value;
    },
    formatDate(time) {
      time = time * 1000
      var date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd hh:mm');
    }
  },
}
</script>
<style>
.jl{
  color: #fff;
  background-color: #2f33b2;
  margin-bottom: 0.5rem;
  font-size: .9rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.duihuan{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(255, 255, 255);
  border-bottom: 1px solid;
  margin-bottom: 1.5rem;
}
.kkk{
  background: #2f33b2;
  padding: 0.3rem;
  width: 8rem;
  text-align: center;
  border-radius: 0.5rem;
}
.cyan{
  background-color: #2f33b2;
  /* padding: 0.5rem; */
  width: 6rem;
  text-align: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.ljqb{
  border-radius: 0.5rem;
  background-color: #2f33b2;
  padding: 0.5rem;
  color: #fff;
  font-size: 0.5rem;
}
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}
.van-cell {
  color: #fff;
  background-color: #1352ff;
}
.van-collapse-item__content {
  color: #fff;
  background-color: #2f33b2;
}
.van-cell--clickable:active {
  background-color: #2f33b2;
}
.van-collapse-item {
  position: relative;
  background: #2f33b2;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
}

.van-hairline--top-bottom {
  /*position: relative;*/
  margin-top: 1rem;
}
</style>
<style scoped>
.van-cell {
  color: #fff;
  background-color: #1352ff;
}
.van-collapse-item__content {
  color: #fff;
  background-color: #1352ff;
}
.van-cell__right-icon {
  margin-left: 4px;
  color: #ffffff;
}
</style>
